import _ from 'lodash'
import { ComputedRef, Ref } from 'vue'
import { utilObject } from './../utils/utilObject'

export interface ICloneObject<T> {
    clone: T
    cloneDiffers: ComputedRef<Boolean>
    resetClone: () => void
}

export function useCloneObject<T = object>(entry: Ref<T>): ICloneObject<T> {
    let clone: T = reactive(_.cloneDeep(entry.value as any))

    watch(entry, () => {
        resetClone()
    })

    const cloneDiffers = computed(() => !_.isEqual(entry.value, clone))
    const resetClone = () => {
        // if check... this is an expensive operation
        if (cloneDiffers) {
            utilObject.resetObject(clone as Object)
            utilObject.assignAll(
                clone as Object,
                _.cloneDeep(entry.value as Object) as Object
            )
        }
    }

    return { clone, cloneDiffers, resetClone }
}
